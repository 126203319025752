
    @import '../assets/scss/cms.scss';

    .checked-list li {
        position: relative;
        padding-left: 30px;
    }

    .checked-list li:before {
        content: url("../assets/img/icons/check2.png");
        position: absolute;
        top: 2px;
        left: 0;
    }

